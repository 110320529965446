import { useEffect, useState } from "react";
import { IoMdRefresh } from "react-icons/io";
import { BsFillPersonFill } from "react-icons/bs";
import PullToRefresh from "react-simple-pull-to-refresh";
import { useLocation, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { useAuth } from "../../auth/components/AuthInit";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../utils/CommonHelper";
import * as actions from "../redux/HomeAction";
import Skeleton from "react-loading-skeleton";
import { Img } from "react-image";
import { Carousel } from "react-bootstrap";

const Image = ({ className, imageUrl, name, onClick }) => {
  return (
    <div onClick={onClick}>
      <Img
        src={imageUrl}
        unloader={<img className={className} alt={name} src={imageUrl} />}
        className={className}
        alt={name}
        decode={false}
        loading="lazy"
        loader={
          <div style={{ position: "relative", width: "100%", height: "100%" }}>
            <div
              style={{
                position: "absolute",
                color: "white",
                fontSize: "2vh",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                width: "100%",
              }}
            >
              {name}
            </div>
            <img
              src={toAbsoluteUrl("/image/defaultimage.png")}
              alt={name}
              className="skeleton"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
        }
      />
    </div>
  );
};

const GameItem = ({ game, handlePlayGame }) => {
  const intl = useIntl();
  const [isShowPlay, setIsShowPlay] = useState(false);

  return (
    <div className="mb-2" style={{ width: "23%" }}>
      <div
        className="rounded company-active position-relative"
        onMouseEnter={() => setIsShowPlay(true)}
        onMouseLeave={() => setIsShowPlay(false)}
      >
        <div>
          <img src={game.imageurl} alt={game.gamename} className="w-100 mb-2" />
          <div
            className="text-center text-truncate px-2 text-font1"
            style={{ color: "white" }}
          >
            {game.gamename}
          </div>
        </div>
        {isShowPlay && (
          <div
            className="position-absolute w-100 top-0 bottom-0 rounded d-flex flex-column align-items-center justify-content-center"
            style={{ background: "rgba(0,0,0,0.8)" }}
          >
            {/* <div className="text-center text-truncate px-2" style={{ color: 'white' }}>{game.gamename}</div> */}
            <button
              type="button"
              className="btn btn-info fw-bold text-font1"
              onClick={() =>
                handlePlayGame(game.gamecode, game.gametype, game.companycode)
              }
            >
              {intl.formatMessage({ id: "play" })}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export const Home = ({}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [category, setCategory] = useState("");
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const { auth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [companyOffset, setCompanyOffset] = useState(
    location.state ? parseInt(location.state.companyOffset) : 0
  );
  const homeState = useSelector(({ home }) => home, shallowEqual);
  const [isLoading, setIsLoading] = useState(false);

  const handlePlayGame = (gameCode, gameType, companyCode) => {
    let playType;
    if (gameType === "slot") {
      playType = 1;
    } else if (gameType === "live") {
      playType = 2;
    } else if (gameType === "fishing") {
      playType = 3;
    } else if (gameType === "sport") {
      playType = 4;
    }
    const currentScrollPosition =
      window.scrollY || document.documentElement.scrollTop;
    navigate(
      `/gamePlay/${companyCode}/${gameCode}/${companyOffset}/${currentScrollPosition}/${playType}`
    );
  };

  useEffect(() => {
    document.body.classList.add(
      "pages-bg",
      "h-100",
      "scroll-y-default",
      "bg-dark"
    );
    document.querySelector("html").classList.add("h-100", "scroll-y-default");
    document
      .querySelector("#root")
      .classList.add("h-100", "w-100", "scroll-y-default", "position-absolute");

    document.body.style.backgroundImage = `url(${toAbsoluteUrl(
      "/image/homebackground.png"
    )})`;

    return () => {
      document.body.style.backgroundImage = ``;
      document.body.classList.remove(
        "pages-bg",
        "h-100",
        "scroll-y-default",
        "bg-dark"
      );
      document
        .querySelector("html")
        .classList.remove("h-100", "scroll-y-default");
      document
        .querySelector("#root")
        .classList.remove(
          "h-100",
          "w-100",
          "scroll-y-default",
          "position-absolute"
        );
    };
  }, []);

  useEffect(() => {
    if (location.state && location.state.gameType) {
      if (location.state.gameType === "live") {
        setCategory("live");
        setCategoryIndex(1);
      } else if (location.state.gameType === "app") {
        setCategory("app");
        setCategoryIndex(2);
      } else if (location.state.gameType === "fishing") {
        setCategory("fishing");
        setCategoryIndex(3);
      } else if (location.state.gameType === "sport") {
        setCategory("sport");
        setCategoryIndex(4);
      } else if (location.state.gameType === "lottery") {
        setCategory("lottery");
        setCategoryIndex(5);
      } else {
        setCategory("slot");
        setCategoryIndex(0);
      }
    } else {
      setCategory("slot");
    }
  }, []);

  useEffect(() => {
    dispatch(
      actions.getHomePageUI({
        action: "mbhomepage",
      })
    ).then(() =>
      dispatch(
        actions.getHomePageMemberData({
          action: "homepagebalance",
        })
      )
    );
  }, []);

  const handleCategoryClick = (index, category) => {
    setCategoryIndex(index);
    setCategory(category);
  };

  const handleRefreshData = () => {
    setIsLoading(true);

    dispatch(
      actions.getHomePageMemberData({
        action: "homepagebalance",
      })
    );

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const handlePullUpRefresh = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (categoryIndex === 6) {
          setCategoryIndex(6);
        } else if (categoryIndex === 0) {
          setCategoryIndex((prevIndex) => prevIndex + 1);
          setCategory("live");
        } else if (categoryIndex === 1) {
          setCategoryIndex((prevIndex) => prevIndex + 1);
          setCategory("app");
        } else if (categoryIndex === 3) {
          setCategoryIndex((prevIndex) => prevIndex + 1);
          setCategory("fishing");
        } else if (categoryIndex === 4) {
          setCategoryIndex((prevIndex) => prevIndex + 1);
          setCategory("sport");
        } else if (categoryIndex === 5) {
          setCategoryIndex((prevIndex) => prevIndex + 1);
          setCategory("lottery");
        }
        resolve();
      }, 100);
    });
  };

  const handleRefresh = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (categoryIndex === 0) {
          setCategoryIndex(0);
        } else if (categoryIndex === 1) {
          setCategoryIndex((prevIndex) => prevIndex - 1);
          setCategory("slot");
        } else if (categoryIndex === 2) {
          setCategoryIndex((prevIndex) => prevIndex - 1);
          setCategory("live");
        } else if (categoryIndex === 3) {
          setCategoryIndex((prevIndex) => prevIndex - 1);
          setCategory("app");
        } else if (categoryIndex === 4) {
          setCategoryIndex((prevIndex) => prevIndex - 1);
          setCategory("fishing");
        } else if (categoryIndex === 5) {
          setCategoryIndex((prevIndex) => prevIndex - 1);
          setCategory("sport");
        }
        resolve();
      }, 100);
    });
  };

  const handleItemClick = (companyCode, gametype) => {
    navigate(
      gametype === "lottery" ? `/${gametype}` : `/${gametype}/${companyCode}`
    );
  };

  const ManageBalance = ({ manageType }) => (
    <div
      onClick={() =>
        navigate(`/setting/${manageType}`, { state: { backTo: "/home" } })
      }
      className="d-flex flex-column align-items-center ms-2"
    >
      <div className="d-flex justify-content-center">
        <img
          src={toAbsoluteUrl(`/image/${manageType}.png`)}
          style={{ width: "30px", height: "30px" }}
        />
      </div>
      <div className="text-font1 text-center text-light">
        {intl.formatMessage({ id: manageType })}
      </div>
    </div>
  );

  return (
    <>
      <div
        className="d-flex flex-column align-items-stretch justify-content-center"
        style={{ height: "20%" }}
      >
        {homeState.homePageUI && homeState.homePageUI.topBannerLists ? (
          <Carousel indicators={false} controls={false}>
            {homeState.homePageUI.topBannerLists.map((topBanner, index) => (
              <Carousel.Item>
                <img
                  key={index}
                  src={topBanner.imagename}
                  className={`d-block w-100 ${index === 0 ? "active" : ""}`}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        ) : (
          <Skeleton className="w-100 h-100" />
        )}
      </div>
      <div>
        <div
          className="border rounded p-2 mx-2"
          style={{ background: "#181818" }}
        >
          <div className="d-flex align-items-center border-bottom pb-2">
            <div
              className="rounded-circle d-flex align-items-center justify-content-center me-2"
              style={{
                width: "20px",
                height: "15px",
                backgroundColor: "rgb(245, 190, 9)",
              }}
            >
              <span className="text-font4 text-light fw-bold">!</span>
            </div>
            {homeState.homePageUI && homeState.homePageUI.topBannerLists && (
              <marquee
                className="text-font3 text-light"
                behavior="scroll"
                direction="left"
              >
                {homeState.homePageUI.anncouncement}
              </marquee>
            )}
          </div>
          <div className="d-flex pe-1 align-items-center">
            <span className="text-font4 text-light pe-1">
              {intl.formatMessage({ id: "welcome" })}
            </span>
            <span className="text-font4" style={{ color: "rgb(245, 190, 9)" }}>
              {auth.username}
            </span>
            {!isLoading && (
              <span className="ms-1" onClick={handleRefreshData}>
                <IoMdRefresh color="white" size={15} />
              </span>
            )}
          </div>
          <div className="d-flex p-1 justify-content-between">
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-center me-3">
                <img
                  className="cursor-pointer"
                  src={toAbsoluteUrl("image/homeProfile.png")}
                  style={{ width: "45px" }}
                  onClick={() => navigate("/setting/profile")}
                />
              </div>
              <div className="d-flex justify-content-center align-items-start">
                <div className="d-flex flex-column align-items-start">
                  <div className="text-font2 text-light">
                    {intl.formatMessage({ id: "currency" })}
                  </div>
                  <div className="text-font2 text-light">ES3</div>
                  <div className="text-font2 text-light">
                    {intl.formatMessage({ id: "game" })}
                  </div>
                </div>
                <div className="d-flex flex-column align-items-start mx-2">
                  <div className="text-font2 text-light">:</div>
                  <div className="text-font2 text-light">:</div>
                  <div className="text-font2 text-light">:</div>
                </div>
                {homeState.homePageMemberData ? (
                  <div className="d-flex flex-column align-items-center">
                    <div className="text-font2 text-light">
                      {isLoading
                        ? "-"
                        : homeState.homePageMemberData.currencycode}
                    </div>
                    <div className="text-font2 text-light">
                      {isLoading
                        ? "-"
                        : homeState.homePageMemberData.es3balance}
                    </div>
                    <div className="text-font2 text-light">
                      {isLoading
                        ? "-"
                        : homeState.homePageMemberData.gamebalance}
                    </div>
                  </div>
                ) : (
                  <Skeleton className="w-100" height="600px" />
                )}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-end">
              <ManageBalance manageType="deposit" />
              <ManageBalance manageType="withdraw" />
              <ManageBalance manageType="transfer" />
            </div>
          </div>
        </div>
        <div className="w-100 d-flex pt-2 justify-content-between px-2">
          <div
            style={{
              overflowY: "auto",
            }}
            className="col-2"
          >
            <div>
              {homeState.homePageMemberData &&
                homeState.homePageMemberData.historyshow === "true" && (
                  <div
                    className="mb-2"
                    onClick={() => handleCategoryClick(6, "history")}
                  >
                    <img
                      src={
                        categoryIndex === 6
                          ? toAbsoluteUrl(`/image/history1.png`)
                          : toAbsoluteUrl(`/image/history.png`)
                      }
                      alt="Slot"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                )}
              <div
                className="mb-2"
                onClick={() => handleCategoryClick(0, "slot")}
              >
                <img
                  src={
                    categoryIndex === 0
                      ? toAbsoluteUrl(`/image/slot1.png`)
                      : toAbsoluteUrl(`/image/slot.png`)
                  }
                  alt="Slot"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div
                className="mb-2"
                onClick={() => handleCategoryClick(1, "live")}
              >
                <img
                  src={
                    categoryIndex === 1
                      ? toAbsoluteUrl(`/image/live1.png`)
                      : toAbsoluteUrl(`/image/live.png`)
                  }
                  alt="Live Casino"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div
                className="mb-2"
                onClick={() => handleCategoryClick(2, "app")}
              >
                <img
                  src={
                    categoryIndex === 2
                      ? toAbsoluteUrl(`/image/app1.png`)
                      : toAbsoluteUrl(`/image/app.png`)
                  }
                  alt="App"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div
                className="mb-2"
                onClick={() => handleCategoryClick(3, "fishing")}
              >
                <img
                  src={
                    categoryIndex === 3
                      ? toAbsoluteUrl(`/image/fishing1.png`)
                      : toAbsoluteUrl(`/image/fishing.png`)
                  }
                  alt="Fishing"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div
                className="mb-2"
                onClick={() => handleCategoryClick(4, "sport")}
              >
                <img
                  src={
                    categoryIndex === 4
                      ? toAbsoluteUrl(`/image/sport1.png`)
                      : toAbsoluteUrl(`/image/sport.png`)
                  }
                  alt="Sport"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div
                className="mb-2"
                onClick={() => handleCategoryClick(5, "lottery")}
              >
                <img
                  src={
                    categoryIndex === 5
                      ? toAbsoluteUrl(`/image/lottery1.png`)
                      : toAbsoluteUrl(`/image/lottery.png`)
                  }
                  alt="Lottery"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className="flex-fill px-2"
            style={{
              overflowY: "auto",
              margin: "0 auto",
            }}
          >
            <PullToRefresh
              onRefresh={handleRefresh}
              onPullUp={handlePullUpRefresh}
              threshold={100}
            >
              {homeState.homePageUI &&
                homeState.homePageUI.gameTypeLists &&
                homeState.homePageUI.gameTypeLists
                  .filter((gameType) => gameType.gametype === category)
                  .map((filteredGameType, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      {filteredGameType.companyGameLists &&
                        filteredGameType.companyGameLists.map(
                          (game, gameIndex) => (
                            <div key={gameIndex}>
                              <Image
                                className="custom-image"
                                imageUrl={game.imageurl}
                                alt={game.companyname}
                                name={game.companyname}
                                onClick={() =>
                                  handleItemClick(
                                    filteredGameType.companyGameLists[gameIndex]
                                      .companycode,
                                    filteredGameType.gametype
                                  )
                                }
                              />

                              <div style={{ height: "5px" }}></div>
                            </div>
                          )
                        )}
                    </div>
                  ))}
            </PullToRefresh>
          </div>
        </div>
      </div>
    </>
  );
};
