import { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "../../../utils/CommonHelper";
import { IoLogoAndroid } from "react-icons/io";
import { FaApple } from "react-icons/fa";

export const DownloadListing = () => {
  const [androidUrl, setAndroidUrl] = useState(null);
  const [iosUrl, setIosUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const downloadState = useSelector(({ download }) => download, shallowEqual);
  const imageUrl = toAbsoluteUrl(
    `/image/${process.env.REACT_APP_API_DOMAIN}/2a.png`
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://dl.dropboxusercontent.com/s/mix1kmb6sqeauh5rtxhet/appversion.txt?rlkey=6111u0tenun467i59kcpxo51m&dl=0"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        } else {
          const data = await response.json();
          setAndroidUrl(data.androidappurl);
          setIosUrl(data.iosappurl);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    if (androidUrl === null || iosUrl === null) {
      fetchData();
    }
  }, [androidUrl, iosUrl]);

  const androidDownload = () => {
    if (androidUrl) {
      const fullUrl =
        androidUrl.startsWith("https://") || androidUrl.startsWith("http://")
          ? androidUrl
          : "https://" + androidUrl;
      window.location.href = fullUrl;
    }
  };

  return (
    <div
      style={{
        backgroundColor: "black",
        backgroundImage: `url(${toAbsoluteUrl("/image/homebackground.png")})`,
        backgroundSize: "cover",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <img
          src={imageUrl}
          alt="Logo"
          style={{ width: "250px", height: "auto" }}
        />
      </div>
      <div
        onClick={androidDownload}
        style={{
          width: "60%",
          height: "40px",
          backgroundColor: "#f5be09",
          color: "black",
          display: "flex",
          margin: "20px",
          borderRadius: "5px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <IoLogoAndroid />
        </div>
        <div style={{ width: "10px" }}></div>
        <div>Android Download</div>
      </div>
      <div
        style={{
          width: "60%",
          height: "40px",
          backgroundColor: "#f5be09",
          color: "black",
          display: "flex",
          borderRadius: "5px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <FaApple />
        </div>
        <div style={{ width: "10px" }}></div>
        <div>Coming Soon</div>
      </div>
    </div>
  );
};
