import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import SweetAlert2 from "react-sweetalert2";
import { PaymentMethod } from "../../../../../constants/DepositNavConst";
import { toAbsoluteUrl } from "../../../../../utils/CommonHelper";
import { DepositAtmDialog } from "./atmDeposit/DepositAtmDialog";
import { DepositGatewayDialog } from "./gatewayDeposit/DepositGatewayDialog";

import Skeleton from "react-loading-skeleton";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ApiStatusTypeEnum } from "../../../../../enumeration/ApiStatusTypeEnum";
import { Overlay } from "../../../../common/components/Overlay";
import * as actions from "../../../redux/SettingAction";
import { DepositTelcoDialog } from "./telcoDeposit/DepositTelcoDailog";

export const DepositDialog = ({ companyCode, dismissBtnRef }) => {
  const [swalProps, setSwalProps] = useState({});
  const [paymentMethod, setPaymentMethod] = useState();
  const intl = useIntl();
  const dispatch = useDispatch();

  const settingState = useSelector(({ setting }) => setting, shallowEqual);

  useEffect(() => {
    dispatch(actions.getDepositWalletUI({ action: "topupwalletui" })).then(
      (res) => {
        const data = res.data;
        if (parseInt(data.code) === ApiStatusTypeEnum.SUCCESS) {
          setPaymentMethod(
            data.topupWalletUIs.find(
              (topupWalletUI) => topupWalletUI.active === "true"
            )?.["paymenttype"]
          );
        }
      }
    );
  }, []);

  return (
    <>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      {settingState.actionsLoading && <Overlay />}
      <div style={{ color: "white", width: "95%", margin: "5px auto" }}>
        {paymentMethod ? (
          <div className="text-input-label mb-2 text-font3">
            {intl.formatMessage({ id: "paymentMethod" })}
          </div>
        ) : (
          <div className="fs-4 m-5 text-center">
            {intl.formatMessage({ id: "noPaymentMethodFound" })}
          </div>
        )}

        <div className="d-flex mb-4">
          {console.log(settingState)}
          {settingState.depositWalletUI &&
            settingState.depositWalletUI.topupWalletUIs &&
            settingState.depositWalletUI.topupWalletUIs
              .filter((topupWalletUI) => topupWalletUI.active === "true")
              .map((topupWalletUI, index) => (
                <div key={index} className="card cursor-pointer me-4">
                  <div
                    style={{
                      background:
                        paymentMethod === topupWalletUI.paymenttype
                          ? "#f5be09"
                          : "#2e323a",
                      display: "flex",
                      width: "80px",
                      height: "80px",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "10px",
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      setPaymentMethod(topupWalletUI.paymenttype);
                    }}
                  >
                    <div
                      className={`d-flex flex-column align-items-center menu-item `}
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={toAbsoluteUrl(
                            `image/${topupWalletUI.paymenttype}.png`
                          )}
                          alt="Image Alt Text"
                          style={{
                            width: "30px",
                            height: "30px",
                            filter:
                              paymentMethod === topupWalletUI.paymenttype
                                ? "brightness(0%)"
                                : "brightness(100%)",
                          }}
                        />
                      </span>
                      <div
                        style={{
                          color:
                            paymentMethod === topupWalletUI.paymenttype
                              ? "black"
                              : "white",
                          textAlign: "center",
                        }}
                        className="text-font2"
                      >
                        {topupWalletUI.paymentname}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
        </div>

        {settingState.depositWalletUI ? (
          paymentMethod === PaymentMethod.ATM ? (
            <DepositAtmDialog
              setSwalProps={setSwalProps}
              companyCode={companyCode}
              dismissBtnRef={dismissBtnRef}
            />
          ) : paymentMethod === PaymentMethod.PAYMENT_GATEWAY ? (
            <DepositGatewayDialog
              setSwalProps={setSwalProps}
              swalProps={swalProps}
              dismissBtnRef={dismissBtnRef}
            />
          ) : paymentMethod === PaymentMethod.TELCO ? (
            <DepositTelcoDialog
              setSwalProps={setSwalProps}
              swalProps={swalProps}
              dismissBtnRef={dismissBtnRef}
            />
          ) : null
        ) : (
          <Skeleton className="w-100" style={{ height: "500px" }} />
        )}
      </div>
    </>
  );
};
